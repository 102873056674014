import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { SharedDataService } from '@src/services/sharedData.service';
import { Observable } from 'rxjs';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersParameters } from '@src/model/filter-parameters/user-parameters';
import { UsersService } from '@src/services/users/users.service';
import { UserNotification } from '@src/model/notification';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss']
})

export class UsersFilterComponent implements OnInit {
  inputFirstNameQuery: string;
  inputLastNameQuery: string;
  inputUserNameQuery: string;
  inputEmailQuery: string;
  inputRoleQuery : string;
  loggedUser: UserProfile;
  layoutDirection: string;
  filterParameters: UsersParameters;
  checkedActive = false;
  checkedInactive = false;
  isLoading = false;
  isOpen = true;

  @Output() changeFilter = new EventEmitter<UsersParameters>();
  @Output() clearFilter = new EventEmitter<UsersParameters>();
  @Input() newfilterParameters : UsersParameters;
  constructor(
    @Inject('AuthService') private authService: IAuthService,
    private userService: UsersService,
    private configService: ConfigService,
    private translateService: TranslateService,
    private notifcationService : NotificationsService,
    private sharedDataService : SharedDataService) {
    this.layoutDirection = this.configService.getLayoutOrientation();
    this.authService.getCurrentUser().subscribe(u => {
      this.loggedUser = u;
    });
  }
  ngOnInit() {
    if (environment.clientName) {
      switch (environment.clientName) {
          case 'messika':
          case 'messikaiframe':
          case 'bellross':
              this.isOpen = false;
              break;
      }
    }
    this.filterParameters = new UsersParameters();
    if(this.newfilterParameters !== undefined)
    {
      this.filterParameters = this.newfilterParameters;
      if(this.newfilterParameters.active)
      {
        this.checkedActive = true;
      }
      if(this.newfilterParameters.inactive)
      {
        this.checkedInactive = true;
      }      
      this.inputFirstNameQuery = this.newfilterParameters.firstName;
      this.inputLastNameQuery = this.newfilterParameters.lastName;
      this.inputUserNameQuery = this.newfilterParameters.userName;
      this.inputRoleQuery = this.newfilterParameters.role;
      this.inputEmailQuery = this.newfilterParameters.email;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  clear() {
    this.inputFirstNameQuery = '';
    this.inputLastNameQuery = '';
    this.inputUserNameQuery = '';
    this.inputRoleQuery = '';
    this.inputEmailQuery = '';
    this.checkedInactive = false;
    this.checkedActive = false;

    this.filterParameters = new UsersParameters();
    this.clearFilter.emit(this.filterParameters);
  }

  changeFirstName(target: any) {

    this.inputFirstNameQuery = target.item.name;
    this.filterParameters.firstName = this.inputFirstNameQuery;
    
    this.changeFilter.emit(this.filterParameters);
  }
  changeLastName(target: any) {

    this.inputLastNameQuery = target.item.name;
    this.filterParameters.lastName = this.inputLastNameQuery;
    this.changeFilter.emit(this.filterParameters);
  }
  changeUserName(target: any) {

    this.inputUserNameQuery = target.item.name;
    this.filterParameters.userName = this.inputUserNameQuery;
    this.changeFilter.emit(this.filterParameters);
  }
  changeEmail(target: any) {
    this.inputEmailQuery = target.item.name;
    this.filterParameters.email = this.inputEmailQuery;
    this.changeFilter.emit(this.filterParameters);
  }

  changeRole(target: any) {
    this.inputRoleQuery = target.item.name;
    this.filterParameters.role = this.inputRoleQuery;
    this.changeFilter.emit(this.filterParameters);
  }

  searchEmailEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.userService.getUsersSuggestions(term, 'email')
      )

  searchFirstNameEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.userService.getUsersSuggestions(term, 'firstname')
      )
      
    searchLastNameEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.userService.getUsersSuggestions(term, 'lastname')
      )
      searchUserNameEntry = (text$: Observable<string>) =>
      text$.debounceTime(200)
        .distinctUntilChanged()
        .switchMap(term =>
          this.userService.getUsersSuggestions(term, 'username')
        )
  searchRoleEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.userService.getUsersSuggestions(term, 'role')
      )

  filterActive() {
    this.filterParameters.active = this.checkedActive;
    this.changeFilter.emit(this.filterParameters);
  }

  filterInactive() {
    this.filterParameters.inactive = this.checkedInactive;
    this.changeFilter.emit(this.filterParameters);
  }

  exportUsers(event) {
    if (event.detail > 1) {
      return;
    }
    this.isLoading = true;
    this.isOpen = true;
    const userNotification = new UserNotification();
     userNotification.descriptionType = 2;
     userNotification.isDeleted = false;
     userNotification.isRead = false;
     userNotification.notificationType = 2;
     userNotification.sourcePageType=2;

    this.userService.getMyUser().subscribe(data => {
      userNotification.userId = data.id;
      this.notifcationService.createNotification(userNotification).subscribe(data => {
        this.filterParameters.parentNotificationId=data.id;
        this.userService.exportUsers(this.filterParameters).subscribe(data => {
          this.isLoading = false;
          this.isOpen = false;
        });
        setTimeout(() => {
          this.sharedDataService.changeMessage("notification:created");
        }, 60000);
      });
   })


  }

  //formatter = (x: { name: string }) => x.name;
  formatterFirstName = (x: { name: string }) =>{ 
    if(this.inputFirstNameQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputFirstNameQuery};
    }
    return x.name;
  };

  formatterLastName = (x: { name: string }) =>{ 
    if(this.inputLastNameQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputLastNameQuery};
    }
    return x.name;
  };

  formatterUserName = (x: { name: string }) =>{ 
    if(this.inputUserNameQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputUserNameQuery};
    }
    return x.name;
  };

  formatterRole = (x: { name: string }) =>{ 
    if(this.inputRoleQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputRoleQuery};
    }
    return x.name;
  };

  formatterEmail = (x: { name: string }) =>{ 
    if(this.inputEmailQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputEmailQuery};
    }
    return x.name;
  };

}
