import { Component, ViewChild } from '@angular/core';
import { ActivationSelectRetailerComponent } from '@src/base-components/activation/steps/select-retailer/activation-select-retailer';
import { WizardPreSelectedRetailerComponentBellRoss } from '@src/clients/bellross/wizard/select-retailer/wizard-pre-selected-retailer';

@Component({
    selector: 'app-activation-select-retailer-bellross',
    templateUrl: './activation-select-retailer.html'
})
export class ActivationSelectRetailerComponentBellRoss extends ActivationSelectRetailerComponent {
    @ViewChild(WizardPreSelectedRetailerComponentBellRoss) wizardPreSelectedRetailerComponent: WizardPreSelectedRetailerComponentBellRoss;
}

