import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { AppInsightsModule } from 'microsoft-applicationinsights-angular5';
import { EnvironmentNames } from '@src/environments/environments.name';
import { AppBaseModule } from '@src/base-components/app-base.module';
import { BellRossRoutingModule } from './bellross-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HttpClient } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MultiTranslateHttpLoader } from '@src/core/MultiTranslateHttpLoader';
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@src/environments/environment';
import { PageLayoutComponent } from '@src/base-components/pagelayout/pagelayout.component';
import { PageLayoutBellRossComponent } from './pagelayout/pagelayout-bellross.component';
import { NavbarBellRossComponent } from './navbar/navbar-bellross.component';

import { BellRossComponent } from './bellross.component';
import { LoginComponent } from '@src/auth/login.component';
import { PageHeaderComponent } from '@src/shared/page-header/page-header.component';
import { DashboardComponents } from '@src/base-components/dashboard/dashboard.components-container';
import { ActivationComponents } from '@src/base-components/activation/activation.components-container';
import { ActivationComponent } from './activation/activation.component';
import { ActivationConfirmationBellRossComponent } from './activation/steps/confirmation/activation-confirmation';
import { ActivationFindProductComponentBellRoss } from './activation/steps/find-product/activation-find-product';
import { ActivationSelectTypeComponentBellRoss } from './activation/steps/select-type/activation-select-type';
import { ActivationResultComponentBellRoss } from './activation/steps/result/activation-result';
import { ActivationFindWCComponentBellRoss } from './activation/steps/find-wc/activation-find-wc';
import { ActivationSelectRetailerComponentBellRoss } from './activation/steps/select-retailer/activation-select-retailer';
import { BellRossWarrantyCertificateComponent } from './warranty-certificate/warranty-certificate.component';
import { ProductOfBellRossComponent } from './product-of-bellross/product-of-bellross.component';
import { WizardFindSerializableProductComponentBellRoss } from './wizard/find-product/wizard-find-serializable-product';
import { WizardFindNotSerializableProductComponentBellRoss } from './wizard/find-product/wizard-find-not-serializable-product';
import { WizardFindProductItemsResultComponentBellRoss } from './wizard/find-product/wizard-find-product-items-result';
import { WizardFindProductsResultComponentBellRoss } from './wizard/find-product/wizard-find-products-result';
import { WizardSelectTypeComponentBellRoss } from './wizard/select-type/wizard-select-type';
import { WizardSelectRetailerComponentBellRoss } from './wizard/select-retailer/wizard-select-retailer';
import { WizardPreSelectedRetailerComponentBellRoss } from './wizard/select-retailer/wizard-pre-selected-retailer';
import { SaleDetailsCorrectionComponent } from './sales/sale-details/product/sale-details-correction.component';
import { SalesComponents } from '@src/base-components/sales/sales.components-container';
import { RetailersComponents } from '@src/base-components/retailers/retailers.components-container';
import { RetailerDetailsComponentBellRoss } from './retailers/retailer-details/retailer-details.component';
import { RetailerDetailsInfoComponentBellRoss } from './retailers/retailer-details/retailer-details-info/retailer-details-info.component';
import { SidebarBellRossComponent } from './sidebar/sidebar-bellross.component';
import { ProfileComponent } from '@src/base-components/profile/profile.component';
import { ProfileComponentBellRoss } from './profile/profile.component';
import { ToastrModule } from 'ngx-toastr';
import { ToastCustomOptions } from './extra/toastr.custom-option';
import { PageNotFoundComponent } from '@src/shared/page-notfound.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SaleDetailsProductBellRossComponent } from './sales/sale-details/product/sale-details-product.component';
import { FindUserByUsernameComponent } from '@src/base-components/users/find-user/find-user-username.component';
import { UserAccessRoleRules } from '@src/auth/user-access-roles-rules';
import { BellRossAccessRules } from './bellross-access-rules';
import { AppSettings } from '@src/core/AppSettings';
import { PrivacyModalContentComponent } from '@src/base-components/privacy/privacy-modal-content.component';
import { ManagementComponents } from '@src/base-components/management/management.container';
import { ManagementBellRossComponents } from './management/management-bellross.container';
import { UsersComponents } from '@src/base-components/users/users.container';
import { RepairsComponents } from '@src/base-components/repairs/repairs.container';
import { CustomersBellRossComponents } from './customers/customers.components-container';
import { InventoriesComponents } from '@src/base-components/inventories/inventories.components-container';
import { ExternalSearchBellRossComponent } from './external-search/external-search-bellross.component';
import { SearchSaleDetailsComponent } from '@src/base-components/external-search/sale-details/sale-details.component';
import { FileUploadModule } from 'ng2-file-upload';
import { BellRossAppSettings } from './BellRossAppSettings';
import { ExternalSearchComponent } from '@src/base-components/external-search/external-search.component';
import { SaleDetailsWarrantiesBellRossComponent } from './sales/sale-details/warranties/sale-details-warranties.component';
import { QRCodePrintingComponents } from '@src/base-components/qr-code-printing/qrcodeprinting.components-container';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalReturnSaleBellRossComponent } from './sales/sale-details/return-sale/modal-return-sale.component';
import { SaleDetailsBellRossComponent } from './sales/sale-details/sale-details.component';
import { SalesBellRossComponent } from './sales/sales.component';
import { InventoryItemAddSerialNumberComponent } from '@src/base-components/inventories/inventory-details/items-table/items-add-serial-number/items-add-serial-number';
import { InventoryItemDeleteSerialNumberComponent } from '@src/base-components/inventories/inventory-details/items-table/items-delete-serial-number/items-delete-serial-number';
import { WizardCreateSerialNumberComponent } from '@src/base-components/wizard/create-searial-number/wizard-create-serial-number.component';
import { ProductsComponents } from '@src/base-components/products/products.components-container';
import { SalesFilterComponent } from '@src/base-components/sales/filter/sales-filter.component';
import { ShipmentsComponents } from '@src/base-components/shipments/shipments.components-container';
import { UserDetailsBellRossComponent } from './users/user-details/user-details.component';
import { AssistanceComponent } from './assistance/assistance.component';
import { TermsComponent } from './terms/terms.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_BASE_URL } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import { NgxScannerQrcodeModule } from "ngx-scanner-qrcode";

// import { registerLocaleData } from '@angular/common';
// import  localeEs from '@angular/common/locales/es';
// import  localeFr from '@angular/common/locales/fr';
// import  localeEn from '@angular/common/locales/en';
// import  localeIt from '@angular/common/locales/it';
// registerLocaleData(localeEs);
// registerLocaleData(localeFr);
// registerLocaleData(localeEn);
// registerLocaleData(localeIt);

export function translateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix: '/locale/',
            suffix: '.json'
        }, {
            prefix: '/locale/custom/privacy.',
            suffix: '.json'
        }, {
            prefix: '/locale/custom/',
            suffix: '.json'
        }
    ]);
}

let instrumentationKey = '';
if (environment.envName === EnvironmentNames.prod) {
    instrumentationKey = '0b0f9723-36fd-4950-bbbe-e839ded444c9';
} else {
    instrumentationKey = '980f2ac3-0a71-4d60-85a7-cc13237cfa84'; //dev
}

@NgModule({
    imports: [
        AppBaseModule.forRoot(),
        AppInsightsModule.forRoot(instrumentationKey),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (translateLoader),
                deps: [HttpClient]
            }
        }),
        BellRossRoutingModule,
        NgxDatatableModule,
        NgxChartsModule,
        ToastrModule.forRoot(ToastCustomOptions),
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        NgxScannerQrcodeModule
    ],
    declarations: [
        PageLayoutComponent,
        PageLayoutBellRossComponent,
        NavbarBellRossComponent,
        BellRossComponent,
        PrivacyModalContentComponent,
        PageHeaderComponent,
        PageNotFoundComponent,
        PrivacyComponent,
        ActivationComponent,
        ActivationConfirmationBellRossComponent,
        ActivationResultComponentBellRoss,
        ActivationSelectTypeComponentBellRoss,
        ActivationFindProductComponentBellRoss,
        ActivationFindWCComponentBellRoss,
        ActivationSelectRetailerComponentBellRoss,
        BellRossWarrantyCertificateComponent,
        ProductOfBellRossComponent,
        WizardFindSerializableProductComponentBellRoss,
        WizardFindNotSerializableProductComponentBellRoss,
        WizardFindProductItemsResultComponentBellRoss,
        WizardFindProductsResultComponentBellRoss,
        WizardSelectTypeComponentBellRoss,
        WizardSelectRetailerComponentBellRoss,
        WizardPreSelectedRetailerComponentBellRoss,
        TermsComponent,
        AssistanceComponent,
        LoginComponent,
        ProfileComponent,
        ProfileComponentBellRoss,
        SearchSaleDetailsComponent,
        FindUserByUsernameComponent,
        InventoryItemAddSerialNumberComponent,
        InventoryItemDeleteSerialNumberComponent,
        SaleDetailsCorrectionComponent,
        SidebarBellRossComponent,
        SaleDetailsProductBellRossComponent,
        ExternalSearchComponent,
        ExternalSearchBellRossComponent,
        SaleDetailsWarrantiesBellRossComponent,
        RetailerDetailsComponentBellRoss,
        RetailerDetailsInfoComponentBellRoss,
        ModalReturnSaleBellRossComponent,
        SaleDetailsBellRossComponent,
        SalesBellRossComponent,
        WizardCreateSerialNumberComponent,
        UserDetailsBellRossComponent,
        ...UsersComponents,
        ...ManagementComponents,
        ...ManagementBellRossComponents,
        ...ActivationComponents,
        ...SalesComponents,
        ...RetailersComponents,
        ...DashboardComponents,
        ...RepairsComponents,
        ...QRCodePrintingComponents,
        ...CustomersBellRossComponents,
        ...InventoriesComponents,
        ...ProductsComponents,
        ...ShipmentsComponents
    ],
    bootstrap: [BellRossComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: AuthConfig, useValue: environment.authConfig },
        { provide: UserAccessRoleRules, useClass: BellRossAccessRules },
        { provide: 'AuthService', useClass: environment.authServiceType },
        { provide: AppSettings, useClass: BellRossAppSettings },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.siteKey,
            } as RecaptchaSettings,
        },
        {
            provide: RECAPTCHA_BASE_URL,
            useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
        }
    ],
    entryComponents: [PrivacyModalContentComponent, ModalReturnSaleBellRossComponent, SalesFilterComponent ]
})
export class BellRossModule { }
