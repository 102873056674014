import { Component } from '@angular/core';
import { WizardPreSelectedRetailerComponent } from '@src/base-components/wizard/select-retailer/wizard-pre-selected-retailer';

@Component({
    selector: 'app-wizard-pre-selected-retailer-bellross',
    templateUrl: './wizard-pre-selected-retailer.html'
})
export class WizardPreSelectedRetailerComponentBellRoss extends WizardPreSelectedRetailerComponent {

    public hideRetailersList() {
        this.isSearching = false;
    }
}

