import { ImportedFileDetailsComponent } from '@src/base-components/management/imported-file-details/imported-file-details.component';
import { ImportedFilesListComponent } from '@src/base-components/management/imported-files-list/imported-files-list.component';
import { ImportedFilesListBellRossComponent } from './imported-files-list/imported-files-list-bellross.component';
import { ImportedProductsMetricsComponent } from '@src/base-components/management/imported-products/imported-products-metrics/imported-products-metrics.component';
import { ImportedProductsEntriesComponent } from '@src/base-components/management/imported-products/imported-products-entries/imported-products-entries.component';
import { ImportedProductsErrorComponent } from '@src/base-components/management/imported-products/imported-products-error-list/imported-products-error-list.component';
import { ImportedProductsLogComponent } from '@src/base-components/management/imported-products/imported-products-log.component';
import { ImportedRetailersMetricsComponent } from '@src/base-components/management/imported-retailers/imported-retailers-metrics/imported-retailers-metrics.component';
import { ImportedRetailersEntriesComponent } from '@src/base-components/management/imported-retailers/imported-retailers-entries/imported-retailers-entries.component';
import { ImportedRetailersErrorComponent } from '@src/base-components/management/imported-retailers/imported-retailers-error-list/imported-retailers-error-list.component';
import { ImportedRetailersLogComponent } from '@src/base-components/management/imported-retailers/imported-retailers-log.component';
import { ManagementBellRossComponent } from './management-bellross.component';
import { ImportedDownloadFileButtonComponent } from '@src/base-components/management/utils/imported-download-file-button/imported-download-file-button.component';
import { ImportedMetricsElementComponent } from '@src/base-components/management/utils/imported-metrics-element/imported-metrics-element.component';

export const ManagementBellRossComponents = [
        ImportedFileDetailsComponent,
        ImportedFilesListComponent,
        ImportedFilesListBellRossComponent,
        ImportedProductsMetricsComponent,
        ImportedProductsEntriesComponent,
        ImportedProductsErrorComponent,
        ImportedProductsLogComponent,
        ImportedRetailersMetricsComponent,
        ImportedRetailersEntriesComponent,
        ImportedRetailersErrorComponent,
        ImportedRetailersLogComponent,
        ImportedDownloadFileButtonComponent,
        ManagementBellRossComponent,
        ImportedMetricsElementComponent
    ];
