import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@src/auth/auth.guard.service';
import { ActivationComponent } from '@src/clients/bellross/activation/activation.component';
import { DashboardComponent } from '@src/base-components/dashboard/dashboard.component';
import { LoginComponent } from '@src/auth/login.component';
import { SaleDetailsComponent } from '@src/base-components/sales/sale-details/sale-details.component';
import { SaleDetailsBellRossComponent } from './sales/sale-details/sale-details.component';
import { SalesBellRossComponent } from './sales/sales.component';
import { ProfileComponentBellRoss } from './profile/profile.component';
import { UsersComponent } from '@src/base-components/users/users.component';
import { UserDetailsBellRossComponent } from './users/user-details/user-details.component';
import { PageNotFoundComponent } from '@src/shared/page-notfound.component';
import { RetailersComponent } from '@src/base-components/retailers/retailers.component';
import { RetailerDetailsComponentBellRoss } from './retailers/retailer-details/retailer-details.component';
import { RetailerDetailsDashboardComponent } from '@src/base-components/retailers/retailer-details/retailer-details-dashboard/retailer-details-dashboard.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ManagementComponent } from '@src/base-components/management/management.component';
import { ManagementBellRossComponent } from './management/management-bellross.component';
import { ImportedRetailersLogComponent } from '@src/base-components/management/imported-retailers/imported-retailers-log.component';
import { ImportedProductsLogComponent } from '@src/base-components/management/imported-products/imported-products-log.component';
import { RepairsComponent } from '@src/base-components/repairs/repairs.component';
import { CustomersComponent } from '@src/base-components/customers/customers.component';
import { CustomerDetailsBellRossComponent } from './customers/customers-details/customer-details.component';
import { ExternalSearchBellRossComponent } from './external-search/external-search-bellross.component';
import { AssistanceComponent } from './assistance/assistance.component';
import { TermsComponent } from './terms/terms.component';
import { InventoriesComponent } from '@src/base-components/inventories/inventories.component';
import { InventoryDetailsComponent } from '@src/base-components/inventories/inventory-details/inventory-details.component';
import { ShipmentsComponent } from '@src/base-components/shipments/shipments.component';
import { ShipmentDetailsComponent } from '@src/base-components/shipments/shipment-details/shipment-details.component';
import { BellRossWarrantyCertificateComponent } from './warranty-certificate/warranty-certificate.component';
import { ProductOfBellRossComponent } from './product-of-bellross/product-of-bellross.component';

const routes : Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  /* {
    path: 'aftersale',
    component: ExternalSearchBellRossComponent
  }, */
  {
    path: 'AfterSalesService/CustomerWarranties',
    component: ExternalSearchBellRossComponent
  },
  {
    path: '',
    redirectTo: '/activation',
    pathMatch: 'full'
  }, {
    path: 'profile',
    component: ProfileComponentBellRoss,
    canActivate: [AuthGuard]
  }, {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      accessRuleKey: 'dashboard'
    }
  }, {
    path: 'repairs',
    component: RepairsComponent,
    canActivate: [AuthGuard],
    data: {
      accessRuleKey: 'repair'
    }
  }, {
    path: 'activation',
    component: ActivationComponent,
    canActivate: [AuthGuard],
    data: {
      accessRuleKey: 'activation'
    }
  }, {
    path: 'sales',
    children: [
      {
        path: '',
        component: SalesBellRossComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'sales'
        }
      }, {
        path: ':id',
        // component: SaleDetailsComponent,
        component: SaleDetailsBellRossComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'sales-details'
        }
      }, {
        path: 'search/:id',
        // component: SaleDetailsComponent,
        component: SaleDetailsBellRossComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'sales-details'
        }
      }, {
        path: 'search/list/:searchTerm',
        component: SalesBellRossComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'sales-details'
        }
      }
    ]
  }, {
    path: 'users',
    children: [
      {
        path: '',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'users'
        }
      }, {
        path: ':id',
        component: UserDetailsBellRossComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'users'
        }
      }
    ]
  }, {
    path: 'user',
    children: [
      {
        path: '',
        component: UserDetailsBellRossComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'user'
        }
      }
    ]
  }, {
    path: 'retailers',
    children: [
      {
        path: '',
        component: RetailersComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'retailers'
        }
      }, {
        path: ':id',
        children: [
          {
            path: '',
            component: RetailerDetailsDashboardComponent,
            canActivate: [AuthGuard],
            data: {
              accessRuleKey: 'retailers'
            }
          },
            {
              path: 'details',
              component: RetailerDetailsComponentBellRoss,
              canActivate: [AuthGuard],
              data: {
                accessRuleKey: 'retailers'
              }
          }
        ]
      }
    ]
  }, {
    path: 'retailer',
    children: [
      {
        path: '',
        component: RetailerDetailsComponentBellRoss,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'retailer'
        }
      }
        // {
        //   path: 'details',
        //   component: RetailerDetailsComponent,
        //   canActivate: [AuthGuard],
        //   data: {
        //     accessRuleKey: 'retailer'
        //   }
      // }
    ]
  },
  {
    path: 'management',

    children: [{
      path: '',
      component: ManagementBellRossComponent,
      canActivate: [AuthGuard],
      data: {
        accessRuleKey: 'management'
      }
    },
    {
      path: 'retailerslogs/:id',
      component: ImportedRetailersLogComponent,
      canActivate: [AuthGuard],
      data: {
        accessRuleKey: 'management'
      }
    },
    {
      path: 'productslogs/:id',
      component: ImportedProductsLogComponent,
      canActivate: [AuthGuard],
      data: {
        accessRuleKey: 'management'
      }
    }
    ]
  },
  {
    path: 'customers',
    children: [
      {
        path: '',
        component: CustomersComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'customers'
        }
      }, {
        path: ':id',
        component: CustomerDetailsBellRossComponent,
        canActivate: [AuthGuard],
        data: {
          accessRuleKey: 'customers'
      }
    }]
  },
  {
    path: 'inventories',
    children: [{
      path: '',
      component: InventoriesComponent,
      canActivate: [AuthGuard],
      data: {
        accessRuleKey: 'inventories',
      },
    },
    {
      path: ':id',
      component: InventoryDetailsComponent,
      canActivate: [AuthGuard],
      data: {
        accessRuleKey: 'inventories',
      },
    }],
  },
  {
    path: 'shipments',
    children: [{
      path: '',
      component: ShipmentsComponent,
      canActivate: [AuthGuard],
      data: {
        accessRuleKey: 'shipments',
      },
    },
    {
      path: ':id',
      component: ShipmentDetailsComponent,
      canActivate: [AuthGuard],
      data: {
        accessRuleKey: 'shipments',
      },
    }],
  },
  {
    path: 'warranty',
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      },
      {
        path: ':id',
        component: BellRossWarrantyCertificateComponent
      }
    ]
  },
  {
    path: 'certificate/bellross/w',
    children: [
      {
        path: '',
        component: PageNotFoundComponent
      },
      {
        path: ':id',
        component: BellRossWarrantyCertificateComponent
      }
    ]
  },
  {
    path: 'product/of/bellross',
    component: ProductOfBellRossComponent
  },
  {
    path: 'privacypolicy',
    component: PrivacyComponent
  },
  {
    path: 'assistance',
    component: AssistanceComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {enableTracing: false})]
})
export class BellRossRoutingModule {}
