import { Component } from '@angular/core';
import { CustomerInfoDetailsComponent } from '@src/base-components/customers/customers-details/info/customer-info-details.component';

@Component(
    {
        selector: 'app-customer-info-details-bellross',
        templateUrl: './customer-info-details.component.html'
    })

export class CustomerInfoDetailsBellRossComponent extends CustomerInfoDetailsComponent {

    setLanguage() : string {
        return this.customer.language != undefined ? this.translateService.instant('LanguagesName.' + this.customer.language) : '';
    }

}
