import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivationSelectTypeComponent } from '@src/base-components/activation/steps/select-type/activation-select-type';

@Component({
    selector: 'app-activation-select-type-bellross',
    templateUrl: './activation-select-type.html'
})
export class ActivationSelectTypeComponentBellRoss extends ActivationSelectTypeComponent {

}
