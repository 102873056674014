import { Component, ChangeDetectorRef, HostListener, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivationFindWCComponent } from '@src/base-components/activation/steps/find-wc/activation-find-wc';
import { WarrantycardsService } from '@src/services/warranty/warrantycards.service';
import { ToastrService } from 'ngx-toastr';

import { environment } from './../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
const CODE_SCANNER_APP_URL = environment.scannerURL;

@Component({
    selector: 'app-activation-find-wc-bellross',
    templateUrl: './activation-find-wc.html',
    styleUrls: ['./activation-find-wc.scss']
})
export class ActivationFindWCComponentBellRoss extends ActivationFindWCComponent implements OnDestroy {
    cameraIframeVisible = false;
    code: string = '';
    barcodeScannerURL: SafeResourceUrl;
    browserLang: string = 'en';

    @ViewChild('barcodeScannerFrame') barcodeScannerFrame!: ElementRef<HTMLIFrameElement>;

    constructor(warrantycardsService: WarrantycardsService,
        public toastrService: ToastrService,
        private translate: TranslateService,
        private ref: ChangeDetectorRef,
        private sanitizer: DomSanitizer) {
        super(warrantycardsService);
        this.barcodeScannerURL = this.sanitizer.bypassSecurityTrustResourceUrl(CODE_SCANNER_APP_URL);
    }

    ngOnInit() {
        // detect lang change
        this.browserLang = this.translate.currentLang;
        this.translate.onLangChange.subscribe((event) => {
            this.browserLang = event.lang;
            this.broadcastMessageToIframe(this.browserLang);
        });
    }

    private broadcastMessageToIframe(data) {
        if (this.barcodeScannerFrame && this.barcodeScannerFrame.nativeElement) {
            const iframeContent = this.barcodeScannerFrame.nativeElement.contentWindow;
            if (iframeContent) {
                const message = { lang: data };
                const targetOriginUrl = CODE_SCANNER_APP_URL;
                iframeContent.postMessage(message, targetOriginUrl);
            } else {
                console.log('Iframe window not available');
            }
        } else {
            console.log('Iframe element not initialized');
        }
    }

    public openCameraIframe() {
        this.cameraIframeVisible = !this.cameraIframeVisible;

        // Pass main frame lang to the iframe app
        setTimeout(() => {
            this.broadcastMessageToIframe(this.browserLang);
        }, 200);
    }

    public closeCameraIframe() {
        this.cameraIframeVisible = false;
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: MessageEvent) {
        // console.log('[main] Broadcast data', event.data);
        if (event.data.code) {
            const code = event.data.code;
            // console.log('[main] Received broadcasted message', code);
            this.code = this.extractValueFromUrl(code);
            this.ref.detectChanges();
            this.getWarrantyCardByCode();
        }
        if (event.data === 'closeIframe') {
            this.closeCameraIframe();
        }
    }

    extractValueFromUrl(codeString) {
        const splitted = codeString.split('/')
        const splittedParams = splitted[splitted.length - 1].split('=');
        const scannedValue = splittedParams[splittedParams.length - 1];
        return scannedValue ? scannedValue : '';
    }

    ngOnDestroy(): void {
        this.closeCameraIframe();;
    }
}
