import { AppSettings } from '@src/core/AppSettings';
import { Injectable } from '@angular/core';

@Injectable()
export class BellRossAppSettings extends AppSettings {
    constructor() {
        super();
        // Overwrite default settings specific for Bell And Ross. Example below:
        this.MaximumDaysAllowedForWarrantyReturn = 180;
        this.MaximumDaysAllowedForWarrantyReturnExtended = 20000;
        this.PrePairedWarrantyCards = false;
        this.MaximumDaysForPastActivationDate = 0;
    }
}
