import { Injectable, Injector, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleForFinalCustomer } from '@src/model/saleForFinalCustomer';
import { BellRossExternalService } from '../services/bellross-external.service';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-bellross-warranty-certificate',
    templateUrl: './warranty-certificate.component.html',
    styleUrls: ['./warranty-certificate.component.scss'],
    providers: [ BellRossExternalService ]
})
export class BellRossWarrantyCertificateComponent implements OnInit {
    public saleForFinalCustomer : SaleForFinalCustomer;
    public cardNumber: string;
    public loader = true;

    // added _ prefix to variables and methods to rename/override the parent ones with the new logic
    constructor (
        injector: Injector,
        private route: ActivatedRoute,
        protected _externalService: BellRossExternalService,
        configService: ConfigService
    ) {
        configService.setIsPublicPage(true);
    }

    ngOnInit() {
        this._externalService.getSaleByWarrantyCardCode(this.route.snapshot.params.id)
            .subscribe(
                (sffc) => {
                    this.saleForFinalCustomer = sffc;
                },
                err => {
                    console.log('HTTP Error', err)
                },
                () => {
                    // request completed
                    this.loader = false;
                }
            );
    }
}
