import {Inject, OnInit, ElementRef} from '@angular/core';
import { Component } from '@angular/core';
import { AppBaseComponent } from '@src/base-components/app-base.component';
import { IAuthService } from '@src/auth/auth.service.interface';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { ConfigService } from '@src/services/layout/config.service';
import { FiltersPages } from '@src/model/config/filtersPages';
import { BellRossPageType } from './services/bellross-page-type.service';

@Component({
  selector: 'app-root',
  templateUrl: './bellross.component.html',
  styleUrls: ['./bellross.component.scss'],
  providers: [ BellRossPageType ]
})
export class BellRossComponent extends AppBaseComponent implements OnInit {
  config: any = {};
  filterOptions: FiltersPages;
    // Set toastr container ref configuration for toastr positioning on screen
  constructor(
      @Inject('AuthService') private authService : IAuthService,
      private elementRef: ElementRef,
      private configService: ConfigService,
      private bellrossPageType: BellRossPageType) {
    super();
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.filterOptions = new FiltersPages(false, true, true, true);

    this.configService.setNewFilters(this.filterOptions);
    this.configService.setPrepairedClient(false);
    this.configService.setCorrectionsOperation(false);

    // sidebar toggle event listner
    let elem = this.elementRef.nativeElement.querySelector('#sidebarToggle');
    if(elem){
      elem.addEventListener('click', () => {WindowEventsDispatcher.fireDelayedResizeEvent(300); });
    }
  }

  public IsInternalPage() {
    return this.bellrossPageType.isInternal();
  }
}
