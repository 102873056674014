import { Component, ViewChild, Inject, OnDestroy, ComponentRef, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable/release';
import { SalesService } from '@src/services/sales/sales.service';
import { User } from '@src/model/user';
import { TranslateService } from '@ngx-translate/core';
import { UserRolesEnum } from '@src/model/userRoles';
import { IAuthService } from '@src/auth/auth.service.interface';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { ConfigService } from '@src/services/layout/config.service';
import { Sale } from '@src/model/sale';
import { SalesFilterComponent } from './filter/sales-filter.component';
import { SalesParameters } from '@src/model/filter-parameters/sales-parameters';
import { SalesFilterOrisComponent } from '@src/clients/oris/sales/filter/sales-filter.component';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-sales',
    templateUrl: './sales.component.html',
    styleUrls: ['./sales.component.scss']
})

export class SalesComponent extends NgxDatatableBase implements OnDestroy, AfterViewInit  {
    model = new PagedTableModel();
    firstLoad = false;
    isLoading = false;
    offset = 0;
    queryTermChanged: Subject<string> = new Subject<string>();
    selected: Sale[] = [];
    showSeller = false;
    showRetailer = false;
    showD2CExtension = false;
    isSeller = false;
    isSearchResult = false;
    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;
    hasNewFilter: boolean;
    filterParameters: SalesParameters;
    newfilterParameters: SalesParameters;
    previousUrl: string;
    currentUrl: string;
    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild(SalesFilterComponent) salesFilterComponent: SalesFilterComponent;
    @ViewChild(SalesFilterOrisComponent) salesFilterOrisComponent: SalesFilterOrisComponent;

    @ViewChild('salesFilterContainer', { read: ViewContainerRef }) container: ViewContainerRef;
    componentRef: ComponentRef<any>;

    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private router: Router,
        private route: ActivatedRoute,
        private salesService: SalesService,
        private translateService: TranslateService,
        private configService: ConfigService,
        private resolver: ComponentFactoryResolver,
        private cdref: ChangeDetectorRef) {
        super(translateService);
        this.model.sortOrder = 'createdOn desc';
        this.showSeller = this.showSellerCol();
        this.showRetailer = this.showRetailerCol();
        this.showD2CExtension = this.showD2CExtensionData();
        this.setTableIconsByOrientation();
        this.hasNewFilter = this.configService.hasNewFilter().sales;
        this.model.rows = [];
        this.isSeller = this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.SELLER]);
        this.currentUrl = this.router.url;
        router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.previousUrl = this.router.url;
                    var str = this.currentUrl.split("/");
                    var str2 = this.previousUrl.split("/");
                    if(str[1] === str2[1])
                    {
                        // same route
                        let filters = localStorage.getItem('salesFilters');
                        let salesFilters = JSON.parse(filters);
                        if(salesFilters !== null)
                        {
                            this.newfilterParameters = new SalesParameters();
                            this.newfilterParameters = salesFilters;
                        }
                    }
                    else{
                        // diferent route 
                        localStorage.removeItem('salesFilters');
                    }
                }
            });
    }

    ngAfterViewInit() {
        const isOrisClient = environment.clientName && environment.clientName === 'oris';
        this.salesFilterComponent = isOrisClient ? this.createFilterOrisComponent() : this.createFilterComponent();

        this.salesFilterComponent.selectedTimeRange = 'last-month';
        const intervalDates = this.salesFilterComponent.computeTimeRange('last-month');
        this.filterParameters = new SalesParameters();
        this.filterParameters.from = intervalDates.from;
        this.filterParameters.to = intervalDates.to;

        if (this.newfilterParameters !== undefined) {
            this.filterParameters = this.newfilterParameters;
        }

        this.isSearchResult = this.router.url.indexOf('search/list') !== -1;

        this.route.params.subscribe(params => {
            const searchTerm = params['searchTerm'];
            if (searchTerm) {
                this.model.isLoading = true;
                this.salesService.getSalesBySerialNumberOrWarrantyCard(searchTerm).subscribe(data => {
                    this.model.rows = data;
                    this.model.totalElements = data.length;
                    this.model.pageNumber = 1;
                    this.model.offset = 1;
                    this.model.isLoading = false;
                    this.firstLoad = true;
                });
            } else {
                this.queryTermChanged
                    .debounceTime(1000) // wait 1000ms after the last event before emitting last event
                    .distinctUntilChanged() // only emit if value is different from previous value
                    .subscribe(v => {
                        this.model.queryTerm = v;
                        this.getSales(1);
                    });
                this.getSales(1);
            }
        });

        this.cdref.detectChanges();
    }

    ngOnDestroy(): void {
        const salesFilters = JSON.stringify({
            from: this.filterParameters.from,
            to: this.filterParameters.to,
            marketId : this.filterParameters.marketId,
            retailerId : this.filterParameters.retailerId,
            businessGroup : this.filterParameters.businessGroup,
            productSku: this.filterParameters.productSku,
            serialNumber: this.filterParameters.serialNumber,
            warrantyCard: this.filterParameters.warrantyCard,
            operationsType : this.filterParameters.operationsType,
            isFake : this.filterParameters.isFake,
            hasD2CExtension : this.filterParameters.hasD2CExtension,
            isDuplicate : this.filterParameters.isDuplicate,
            selectedTimeRange: this.filterParameters.selectedTimeRange
        });
        localStorage.setItem('salesFilters', salesFilters);
        this.componentRef.destroy();
    }
    onSort(event) {
        if (this.model.isLoading) {
            return;
        }
        this.model.sortOrder = `${event.column.prop} ${event.newValue}`;
        this.getSales(1);
    }
    onSearch(event: any) {
        this.queryTermChanged.next(event.target.value.trim());
    }
    setPage(pageInfo) {
        this.getSales(pageInfo.page);
    }
    private showSellerCol() {
        return this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.RETAILER]);
    }
    private showRetailerCol() {
        return this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.AGENT, UserRolesEnum.MASTER_RETAILER,UserRolesEnum.MARKET_ADMIN]);
    }
    private showD2CExtensionData() {
        return environment.clientName && environment.clientName === 'tag';
    }
    getUserName(u: User) {
        return `${u.firstName} ${u.lastName}`;
    }
    getLastAction(action: string) {
        return this.translateService.instant(`Components.Sales.operations.${action.toLowerCase()}`);
    }
    getSales(page: number) {
        if (this.model.isLoading) {
            return;
        }
        if (page < 1) {
            page = 1;
        }
        this.model.isLoading = true;
        if (this.hasNewFilter === true) {
            this.filterSales(page);
        } else {
            this.querySales(page);
        }
    }

    private querySales(page: number) {
        this
            .salesService
            .querySales(this.model.queryTerm, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = data.body.value;
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }

    private filterSales(page: number) {
        this
            .salesService
            .filterSales(this.filterParameters, this.model.sortOrder, this.model.pageSize, page)
            .subscribe(data => {
                this.model.rows = data.body.value;
                this.model.totalElements = data.headers.get('pageCount');
                this.model.pageNumber = page;
                this.model.offset = page - 1;
                if (this.firstLoad) {
                    // When the page changes and the lst page has little items, the width is incorrectly displayed (scrollbar problems)
                    // Force table size recalculation and re-render.
                    // this.table.recalculate();
                    WindowEventsDispatcher.fireDelayedResizeEvent(0);
                }
                this.firstLoad = true;
                this.model.isLoading = false;
            });
    }

    updateSales(event) {
        this.filterParameters = event;
        this.getSales(1);
    }

    GoToSaleDetails() {
        if (this.isSearchResult) {
            this.router.navigateByUrl(`/sales/search/${this.selected[0].id}`);
        } else {
            this.router.navigateByUrl(`sales/${this.selected[0].id}`);
        }
    }
    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }

    createFilterOrisComponent() {
        this.container.clear();
        const factory = this.resolver.resolveComponentFactory(SalesFilterOrisComponent);
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.hidden = !this.hasNewFilter && this.isSeller;
        this.componentRef.instance.newfilterParameters = this.newfilterParameters;
        (<SalesFilterOrisComponent>this.componentRef.instance).clearFilter.subscribe((event: any) => { this.updateSales(event); });
        (<SalesFilterOrisComponent>this.componentRef.instance).changeFilter.subscribe((event: any) => { this.updateSales(event); });

        return this.componentRef.instance;
    }

    createFilterComponent() {
        this.container.clear();
        const factory = this.resolver.resolveComponentFactory(SalesFilterComponent);
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.hidden = !this.hasNewFilter && this.isSeller;
        this.componentRef.instance.newfilterParameters = this.newfilterParameters;
        (<SalesFilterComponent>this.componentRef.instance).clearFilter.subscribe((event: any) => { this.updateSales(event); });
        (<SalesFilterComponent>this.componentRef.instance).changeFilter.subscribe((event: any) => { this.updateSales(event); });

        return this.componentRef.instance;
    }
}
