import { Component, Inject, ViewChild } from '@angular/core';
import { ActivationBaseComponent } from '@src/base-components/activation/activation.component';

import * as enums from '@src/model/Enums/productType';
//children

import { ActivationSelectRetailerComponentBellRoss } from './steps/select-retailer/activation-select-retailer';
import { ActivationSelectTypeComponent } from '@src/base-components/activation/steps/select-type/activation-select-type';
import { ActivationFindProductComponent } from '@src/base-components/activation/steps/find-product/activation-find-product';
import { ActivationFindWCComponentBellRoss } from './steps/find-wc/activation-find-wc';
import { ProductType } from '@src/model/Enums/productType';
import { ProductsService } from '@src/services/products/products.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@src/services/users/users.service';
import { ConfigService } from '@src/services/layout/config.service';
import { ToastrService } from 'ngx-toastr';
import { ProductItem } from '@src/model/productItem';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent extends ActivationBaseComponent {

  @ViewChild(ActivationSelectRetailerComponentBellRoss) activationSelectRetailerComponentBellRoss: ActivationSelectRetailerComponentBellRoss;
  @ViewChild(ActivationSelectTypeComponent) activationSelectTypeComponent: ActivationSelectTypeComponent;
  @ViewChild(ActivationFindProductComponent) activationFindProductComponent: ActivationFindProductComponent;
  @ViewChild(ActivationFindWCComponentBellRoss) activationFindWCComponentBellRoss: ActivationFindWCComponentBellRoss;

  productType = enums.ProductType;

  limitedEditionUnitNumber: number;

  constructor(
    @Inject('AuthService') public authService: IAuthService,
    public warrantiesService: WarrantiesService,
    public retailerService: RetailersService,
    public translateService: TranslateService,
    public usersService: UsersService,
    public configService: ConfigService,
    public toastrService: ToastrService,
    public productsService: ProductsService
  ) {
    super(authService, warrantiesService, retailerService, translateService, usersService, configService, toastrService);
  }

  selectProduct(prod: ProductItem) {
    this.selectedProductItem = prod;
    this.isFakeActivation = this.selectedType === ProductType.serialized || this.selectedType == undefined ? true : false;

    this.wizard.navigation.goToNextStep();
  }

  resetToStep0() {
    if (this.activationSelectRetailerComponentBellRoss) {
      this.activationSelectRetailerComponentBellRoss.reset();
    }
    this.resetToStep1();
  }

  resetToStep1() {
    if (this.activationSelectTypeComponent) {
      this.activationSelectTypeComponent.reset();
    }
    this.resetToStep2();
  }

  resetToStep2() {
    if (this.activationFindProductComponent) {
      this.activationFindProductComponent.reset();
    }
    this.resetToStep3();
  }

  resetToStep3() {
    if (this.activationFindWCComponentBellRoss) {
      this.activationFindWCComponentBellRoss.reset();
    }
    this.resetToStep4();
  }

  resetToStep4() {
    this.validationErrors = [];
  }

  setTitleProductType() {
    return this.selectedType == undefined ? this.translateService.instant('Components.ActivationSteps.step-type') :
      this.selectedType == 1 ? this.translateService.instant('CustomLabels.post-2017-title')
        : this.selectedType == 2 ? this.translateService.instant('CustomLabels.pre-2017-title') : '';
  }

  updateLimitedEditionUnitNumber(limitedEditionUnitNumber: number) {
    this.limitedEditionUnitNumber = limitedEditionUnitNumber;
  }

  generateWarranty(productItemForLimitedEdition: ProductItem) {
    this.activateWarrantydisable = true;
    this.warranty.warrantyCardCode = this.selectedWarrantyCard.code;
    this.warranty.warrantyCardId = this.selectedWarrantyCard.id;
    this.warranty.warrantyActivationDate = this.activationDate;
    this.warranty.isFake = this.isFakeActivation;
    this.warranty.soldBy = this.isGeneriqueSeller ? this.soldBy : "";
    this.warranty.sellerId = this.selectedSeller.id;
    this.warranty.retailerId = this.selectedRetailer.id;
    this.warranty.distribution = 'Website';
    this.warranty.channelOption = this.selectedOption; 
    this.warranty.isCpo = this.cpoActivation;
    this.warranty.isOverrideWarranty = this.owActivation;

    if (productItemForLimitedEdition) {
      this.warranty.productItemId = productItemForLimitedEdition.id;
      this.warranty.productId = productItemForLimitedEdition.product.id;
      this.warranty.isSerializedItem = false;
      this.warranty.hasBlockchainCertificate = productItemForLimitedEdition.isValid;
      this.warranty.productItemSerialNumber = productItemForLimitedEdition.serialNumber;
      this.warranty.productSku = productItemForLimitedEdition.product.sku;
      this.warranty.productName = productItemForLimitedEdition.product.name;
      this.warranty.limitedEditionUnitNumber = this.limitedEditionUnitNumber;
    }
    else {
      this.warranty.productItemId = this.selectedProductItem.id;
      this.warranty.productId = this.selectedProductItem.product.id;
      this.warranty.isSerializedItem = true;
      this.warranty.hasBlockchainCertificate = this.selectedProductItem.isValid;
      this.warranty.productItemSerialNumber = this.selectedProductItem.serialNumber;
      this.warranty.productSku = this.selectedProductItem.product.sku;
      this.warranty.productName = this.selectedProductItem.product.name;
      this.warranty.limitedEditionUnitNumber = 0;
    }

    this.warrantiesService.addWarranty(this.warranty).subscribe(
      w => {
        this.activateWarrantydisable = false;
        if (w) {
          this.warrantyActivationResult = 's';
          this.warrantyCode = w.warrantyCard && this.selectedType === ProductType.serialized ? w.warrantyCard.code : null;

          this.wizard.navigation.goToNextStep();
          this.channelOption = null;
        } else {
          this.warrantyActivationResult = 'e';
          this.channelOption = null;
        }
      }
    );
    this.selectedOption = "";
    this.cpoActivation = false;
    this.owActivation = false;
  }

  activateWarrantyAction(event, skipCRM) {
    if (!this.canActivate(skipCRM)) {
      return;
    }
    if (event.detail > 1) {
      return;
    }
    if (this.warranty.customerInfo != null && this.warranty.customerInfo.acceptedTerms != undefined) {
      this.warranty.customerInfo.acceptedTerms = this.warranty.customerInfo.acceptedTerms.toString() == 'true';
    }
    if (this.channelOption != null && this.selectedOption == "") {
      this.toastrService.warning('Please Select Channel Option');
      return;
      //this.validationErrors.push('acceptedTerms');
    }
    if ((this.cpoActivation || this.owActivation) && (this.warranty.warrantyDurationMonths == undefined || this.warranty.warrantyDurationMonths == 0))
    {
      this.toastrService.warning('Please Select a valid warranty durations');
      return;
    }
    if (!this.selectedProductItem.serialNumber && (this.limitedEditionUnitNumber == undefined || this.limitedEditionUnitNumber < 1 || this.limitedEditionUnitNumber > this.selectedProductItem.product.limitedEditionUnits))
    {
      this.toastrService.warning('Limited Edition unit number should be between 1 and ' + this.selectedProductItem.product.limitedEditionUnits);
      return;
    }
    if (this.selectedType === undefined || this.selectedType === ProductType.serialized) {
      this.generateWarranty(null);
    }
    else {
      this.productsService.getLimitedEditionProductItem(this.selectedProductItem.product.sku, this.limitedEditionUnitNumber).subscribe(
        (productItem) => {
          if (!productItem) {
            this.toastrService.warning('Please, select a not used Limited Edition Unit Number');
          }
          else {
            this.generateWarranty(productItem);
          }
        }
      );
    }
  }
}
