import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { Metric } from '../../model/metric';
import * as moment from 'moment';
import { MetricWithLocation } from '../../model/metricWithLocation';
import { ExportedFile } from '../../model/exportedFile';
import { ExportSalesParameters } from '@src/model/filter-parameters/export-sales-parameters';

@Injectable()
export class DashboardService extends BaseService {

    protected baseUrl = environment.apiBaseUrl + '/api/sales';
    constructor(injector: Injector) {
        super(injector);
    }

    getSalesVolumeMetricsByProductModel(interval: string, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/sales/byproductmodel/volume?interval=${interval}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getSalesVolumeMetricsByProductId(interval: string, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/sales/byproductid/volume?interval=${interval}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getSalesValueMetricsByProductModel(interval: string, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/sales/byproductmodel/value?interval=${interval}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getSalesValueMetricsByProductId(interval: string, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/sales/byproductid/value?interval=${interval}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopRetailersVolumeMetricsByProductModel(top: number, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topretailers/byproductmodel/volume?top=${top}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopRetailersVolumeMetricsByProductId(top: number, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topretailers/byproductid/volume?top=${top}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopRetailersValueMetricsByProductModel(top: number, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topretailers/byproductmodel/value?top=${top}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopRetailersValueMetricsByProductId(top: number, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topretailers/byproductid/value?top=${top}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopProductsVolumeMetricsByProductModel(top: number, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topproducts/byproductmodel/volume?top=${top}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopProductsVolumeMetricsByProductId(top: number, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topproducts/byproductid/volume?top=${top}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopProductsValueMetricsByProductModel(top: number, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topproducts/byproductmodel/value?top=${top}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopProductsValueMetricsByProductId(top: number, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topproducts/byproductid/value?top=${top}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopCollectionsVolumeMetricsByProductModel(top: number, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topcollections/byproductmodel/volume?top=${top}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopCollectionsVolumeMetricsByProductId(top: number, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topcollections/byproductid/volume?top=${top}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopCollectionsValueMetricsByProductModel(top: number, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topcollections/byproductmodel/value?top=${top}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopCollectionsValueMetricsByProductId(top: number, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topcollections/byproductid/value?top=${top}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopFamiliesVolumeMetricsByProductModel(top: number, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topfamilies/byproductmodel/volume?top=${top}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopFamiliesVolumeMetricsByProductId(top: number, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topfamilies/byproductid/volume?top=${top}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopFamiliesValueMetricsByProductModel(top: number, from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topfamilies/byproductmodel/value?top=${top}&` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTopFamiliesValueMetricsByProductId(top: number, from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/charts/topfamilies/byproductid/value?top=${top}&` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getRetailersMapDataByProductModel(from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<MetricWithLocation[]>(`${url}/metrics/retailersmapdata/byproductmodel?` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getRetailersMapDataByProductId(from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0) {
        const url = this.baseUrl;
        return this.get<MetricWithLocation[]>(`${url}/metrics/retailersmapdata/byproductid?` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }
 
    exportSalesFromDashboardByProductModel(from = null, to = null, productModel = null, familyId = 0, marketId = 0, retailerId = 0, parentNotificationId = 0) {
        const url = this.baseUrl;
        return this.get<string[]>(`${url}/metrics/exportsalesfromdashboard/byproductmodel?` +
            `${this.buildQueryParameters('productModel', productModel)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('parentNotificationId', parentNotificationId)}`
        );
    }
 
    exportSalesFromDashboardByProductId(from = null, to = null, productId = 0, familyId = 0, marketId = 0, retailerId = 0, parentNotificationId = 0) {
        const url = this.baseUrl;
        return this.get<string[]>(`${url}/metrics/exportsalesfromdashboard/byproductid?` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('parentNotificationId', parentNotificationId)}`
        );
    }

    

    getRetailersMetrics({ productId = null, familyId = null, marketId = null, retailerId = null, from = null, to = null }
        : {  productId?: number, familyId?: number,marketId?: number, retailerId?: number, from?: Date, to?: Date, entryId?:number } = {}) {
        const url = this.baseUrl;
        return this.get<MetricWithLocation[]>(`${url}/metrics/retailers?` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}&` 
        );
    }

    getTopSellersMetrics(top: number, retailerId: number = null, from = null, to = null) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/metrics/topsellers?top=${top}&${this.buildQueryParameters('retailerId', retailerId)}&` +
        `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
        `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getSalesMetricsByTypes({ productId = null, familyId = null, retailerId = null, from = null, to = null, marketId = null }
        : { productId?: number, familyId?: number, retailerId?: number, from?: Date, to?: Date, marketId?: number, }) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/metrics/types?` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTotalActivations({ retailerId = null, from = null, to = null }
        : { retailerId?: number, from?: Date, to?: Date } = {}) {
        const url = this.baseUrl;
        return this.get<number>(`${url}/metrics/count?` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }
    getLast3MonthsActivations({ retailerId = null }
        : { retailerId?: number } = {}) {
        const now = new Date();
        const from = moment().add(-2, 'M').toDate();
        return this.getTotalActivations({
            retailerId: retailerId,
            from: new Date(from.getUTCFullYear(), from.getUTCMonth(), 1),
            to: new Date(now.getUTCFullYear(), now.getUTCMonth() + 1, 1)
        });
    }

    getTotalActivationsThisYear({ retailerId = null }
        : { retailerId?: number } = {}) {
        const now = new Date();
        return this.getTotalActivations({
            retailerId: retailerId,
            from: new Date(now.getUTCFullYear(), 0, 1),
            to: new Date(now.getUTCFullYear() + 1, 0, 1)
        });
    }

    getTotalActivationsThisMonth({ retailerId = null }
        : { retailerId?: number } = {}) {
        const now = new Date();
        return this.getTotalActivations({
            retailerId: retailerId,
            from: new Date(now.getUTCFullYear(), now.getUTCMonth(), 1),
            to: new Date(now.getUTCFullYear(), now.getUTCMonth() + 1, 1)
        });
    }

    getTotalActivationsToday({ retailerId = null }
        : { retailerId?: number } = {}) {
        const now = new Date();
        return this.getTotalActivations({
            retailerId: retailerId,
            from: new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()),
            to: new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1)
        });
    }

    exportSales(exportSales: ExportSalesParameters) {
        let url  = this.baseUrl + `/export?`;
        exportSales.productIds.forEach(element => {
            url = url + `${this.buildQueryParameters('productId', element)}&`
        });

        exportSales.familyIds.forEach(element => {
            url = url +`${this.buildQueryParameters('familyId', element)}&`
        });

        exportSales.operationsType.forEach(element => {
            url = url + `${this.buildQueryParameters('operationType', element)}&`;
            });

        url = url + `${this.buildQueryParameters('serialNumber', exportSales.serialNumber, )}&` +
        `${this.buildQueryParameters('warrantyCard', exportSales.warrantyCard, )}&` +
        `${this.buildQueryParameters('marketId', exportSales.marketId, )}&` +
        `${this.buildQueryParameters('retailerId', exportSales.retailerId, )}&` +
        `${this.buildQueryParameters('productSku', exportSales.productSku, )}&` +
        `${this.buildQueryParameters('isFake', exportSales.isFake, )}&` +
        `${this.buildQueryParameters('isDuplicate', exportSales.isDuplicate, )}&` +
        `${this.buildQueryParameters('from', exportSales.from == null ? null : moment(exportSales.from).format('YYYY-MM-DD'))}&` +
        `${this.buildQueryParameters('to', exportSales.to == null ? null : moment(exportSales.to).format('YYYY-MM-DD'))}&`+
        `${this.buildQueryParameters('parentNotificationId', exportSales.parentNotificationId, )}`;

        return this.get<string>(url);
    }
}