import { Component } from '@angular/core';
import { ProductsService } from '@src/services/products/products.service';
import { WizardFindNotSerializableProductComponent } from '@src/base-components/wizard/find-product/wizard-find-not-serializable-product';

@Component({
    selector: 'app-wizard-find-not-serializable-product-bellross',
    templateUrl: './wizard-find-not-serializable-product.html'
})
export class WizardFindNotSerializableProductComponentBellRoss extends WizardFindNotSerializableProductComponent{
   
    constructor(productsService: ProductsService) { 
        super(productsService)
    }
}
