import { Component } from '@angular/core';
import { SalesComponent } from '@src/base-components/sales/sales.component';

@Component({
    selector: 'app-sales',
    templateUrl: './sales.component.html',
    styleUrls: ['./sales.component.scss']
})

export class SalesBellRossComponent extends SalesComponent {
 }