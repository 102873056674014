import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DashboardService } from '@src/services/dashboard/dashboard.service';
import { DashboardDefaultFilterComponent } from './filter/dashboard-default-filter.component';
import { MetricWithLocation } from '@src/model/metricWithLocation';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { formatDate } from '@angular/common';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { UserNotification } from '@src/model/notification';
import { UsersService } from '@src/services/users/users.service';
import { SharedDataService } from '@src/services/sharedData.service';

@Component({
  selector: 'app-dashboard-default',
  templateUrl: './dashboard-default.component.html',
  styleUrls: ['./dashboard-default.component.scss']
})

export class DashboardDefaultComponent implements OnInit {
  @Input() isRetailerDashboard: boolean = false;
  @Input() retailerId: number = 0;

  salesVolume: { name: string, value: number }[] = [];
  salesValue: { name: string, value: number }[] = [];
  topRetailersVolume: { name: string, value: number }[] = [];
  topRetailersValue: { name: string, value: number }[] = [];
  topProductsVolume: { name: string, value: number }[] = [];
  topProductsValue: { name: string, value: number }[] = [];
  topCollectionsVolume: { name: string, value: number }[] = [];
  topCollectionsValue: { name: string, value: number }[] = [];
  topFamiliesVolume: { name: string, value: number }[] = [];
  topFamiliesValue: { name: string, value: number }[] = [];
  totalActivations: { name: string, value: number }[] = [];
  topRetailers = 10;
  topProducts = 10;
  topCollections = 10;
  topFamilies = 100;

  retailersSales: MetricWithLocation[] = [];

  isLoadingSalesVolume = false;
  isLoadingSalesValue = false;
  isLoadingTopRetailersVolume = false;
  isLoadingTopRetailersValue = false;
  isLoadingTopProductsVolume = false;
  isLoadingTopProductsValue = false;
  isLoadingTopCollectionsVolume = false;
  isLoadingTopCollectionsValue = false;
  isLoadingTopFamiliesVolume = false;
  isLoadingTopFamiliesValue = false;

  from: Date;
  to: Date;
  interval: string;
  marketId: number = 0;
  familyId: number = 0;
  productId: number = 0;

  clearEntriesFlag: boolean;
  warningActivations: boolean;
  loggedUser: UserProfile;
  retailersId: any[];
  displayMap = false;
  canExportSales = false;

  isLoadingExport = false;

  @ViewChild(DashboardDefaultFilterComponent) dashboardDefaultFilterComponent: DashboardDefaultFilterComponent;

  constructor(
    private dashboardService: DashboardService,
    private notificationService: NotificationsService,
    private usersService: UsersService,
    private configService: ConfigService,
    private sharedDataService: SharedDataService) {
  }

  ngOnInit() {
    this.dashboardDefaultFilterComponent.selectedTimeRange = 'last-month';

    const dateStr = this.dashboardDefaultFilterComponent.computeTimeRange('last-month');
    this.from = dateStr.from;
    this.to = dateStr.to;
    this.interval = dateStr.interval;

    this.getData();
  }

  getData() {
    this.getRetailersMapData();

    this.getSalesVolumeMetrics();
    this.getSalesValueMetrics();
    this.getTopRetailersVolumeMetrics();
    this.getTopRetailersValueMetrics();
    this.getTopProductsVolumeMetrics();
    this.getTopProductsValueMetrics();
    this.getTopCollectionsVolumeMetrics();
    this.getTopCollectionsValueMetrics();
    this.getTopFamiliesVolumeMetrics();
    this.getTopFamiliesValueMetrics();

    this.getTotalActivations();
  }

  getRetailersMapData() {
    this.dashboardService.getRetailersMapDataByProductId(this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.retailersSales = data;
    });
  }

  getSalesVolumeMetrics() {
    this.isLoadingSalesVolume = true;
    this.dashboardService.getSalesVolumeMetricsByProductId(this.interval, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.salesVolume = data.map(m => {
        const dateStr = this.interval == "Year" ? formatDate(m.label, 'y', 'en-US') : this.interval == "Month" ? formatDate(m.label, 'MMMM, y', 'en-US') : formatDate(m.label, 'd/M/y', 'en-US');
        return { name: dateStr, value: m.value };
      });
      this.isLoadingSalesVolume = false;
    });
  }

  getSalesValueMetrics() {
    this.isLoadingSalesValue = true;
    this.dashboardService.getSalesValueMetricsByProductId(this.interval, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.salesValue = data.map(m => {
        const dateStr = this.interval == "Year" ? formatDate(m.label, 'y', 'en-US') : this.interval == "Month" ? formatDate(m.label, 'MMMM, y', 'en-US') : formatDate(m.label, 'd/M/y', 'en-US');
        return { name: dateStr, value: m.value };
      });
      this.isLoadingSalesValue = false;
    });
  }

  getTopRetailersVolumeMetrics() {
    this.isLoadingTopRetailersVolume = true;
    this.dashboardService.getTopRetailersVolumeMetricsByProductId(this.topRetailers, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.topRetailersVolume = data.map(m => {
        return { name: m.label, value: m.value };
      });
      this.isLoadingTopRetailersVolume = false;
    });
  }

  getTopRetailersValueMetrics() {
    this.isLoadingTopRetailersValue = true;
    this.dashboardService.getTopRetailersValueMetricsByProductId(this.topRetailers, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.topRetailersValue = data.map(m => {
        return { name: m.label, value: m.value };
      });
      this.isLoadingTopRetailersValue = false;
    });
  }

  getTopProductsVolumeMetrics() {
    this.isLoadingTopProductsVolume = true;
    this.dashboardService.getTopProductsVolumeMetricsByProductId(this.topProducts, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.topProductsVolume = data.map(m => {
        return { name: m.label, value: m.value };
      });
      this.isLoadingTopProductsVolume = false;
    });
  }

  getTopProductsValueMetrics() {
    this.isLoadingTopProductsValue = true;
    this.dashboardService.getTopProductsValueMetricsByProductId(this.topProducts, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.topProductsValue = data.map(m => {
        return { name: m.label, value: m.value };
      });
      this.isLoadingTopProductsValue = false;
    });
  }

  getTopCollectionsVolumeMetrics() {
    this.isLoadingTopCollectionsVolume = true;
    this.dashboardService.getTopCollectionsVolumeMetricsByProductId(this.topCollections, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.topCollectionsVolume = data.map(m => {
        return { name: m.label, value: m.value };
      });
      this.isLoadingTopCollectionsVolume = false;
    });
  }

  getTopCollectionsValueMetrics() {
    this.isLoadingTopCollectionsValue = true;
    this.dashboardService.getTopCollectionsValueMetricsByProductId(this.topCollections, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.topCollectionsValue = data.map(m => {
        return { name: m.label, value: m.value };
      });
      this.isLoadingTopCollectionsValue = false;
    });
  }

  getTopFamiliesVolumeMetrics() {
    this.isLoadingTopFamiliesVolume = true;
    this.dashboardService.getTopFamiliesVolumeMetricsByProductId(this.topFamilies, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.topFamiliesVolume = data.map(m => {
        return { name: m.label, value: m.value };
      });
      this.isLoadingTopFamiliesVolume = false;
    });
  }

  getTopFamiliesValueMetrics() {
    this.isLoadingTopFamiliesValue = true;
    this.dashboardService.getTopFamiliesValueMetricsByProductId(this.topFamilies, this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId).subscribe(data => {
      this.topFamiliesValue = data.map(m => {
        return { name: m.label, value: m.value };
      });
      this.isLoadingTopFamiliesValue = false;
    });
  }


  getTotalActivations() {

    // let request  = this.entryType === 'f' ?
    // {
    //   marketId: this.marketId,
    //   retailerId: this.retailerId,
    //   to: this.to,
    //   from: this.from,
    //   familyId:this.entryId }
    // : {
    //   marketId: this.marketId,
    //   retailerId: this.retailerId,
    //   to: this.to,
    //   from: this.from,
    //   productId:this.entryId
    // };

    // this.dashboardService.getSalesMetricsByTypes(request).subscribe(data => {
    //     this.totalActivations = data.sort((a, b) => b.value - a.value).map(m => {
    //       if ((m.label === 'Duplication' || m.label === 'Fake') && m.value > 0) {
    //         this.warningActivations = true;
    //       } else {
    //         this.warningActivations = false;
    //       }
    //       return { name: m.label, value: m.value };
    //     });
    //     this.isLoading = false;
    //   });
  }

  changeRangeTime({ from, to, interval }: { from: Date, to: Date, interval: string }) {
    this.from = from;
    this.to = to;
    this.interval = interval;
    this.getData();
  }

  changeLocation({ marketId, retailerId }: { marketId?: number, retailerId?: number }) {
    this.marketId = marketId;
    this.retailerId = retailerId;
    this.getData();
  }

  changeProductInfo({ familyId, productId }: { familyId?: number, productId?: number }) {
    this.familyId = familyId;
    this.productId = productId;
    this.getData();
  }

  clearFilter({ from, to, interval, marketId, retailerId, familyId, productId }
    : { from: Date, to: Date, interval: string, marketId?: number, retailerId?: number, familyId?: number, productId?: number }) {
    this.from = from;
    this.to = to;
    this.interval = interval;
    this.familyId = familyId;
    this.productId = productId;
    this.retailerId = retailerId;
    this.marketId = marketId;
    this.getData();
  }

  handleMap(event) {
    if (event === true) {
      this.getRetailersMapData();
    }
    this.displayMap = event;
  }

  // handleExportSales(event) {
  //   if (event === true) {
  //     this.dashboardSalesChartComponent.canExportSales = event;
  //   }
  // }

  // loadingExportSales(event) {
  //   this.isLoadingExport = event;
  // }

  exportSales(event) {
    const userNotification = new UserNotification();
    userNotification.descriptionType = 2;
    userNotification.isDeleted = false;
    userNotification.isRead = false;
    userNotification.notificationType = 2;
    userNotification.sourcePageType = 1

    this.usersService.getMyUser().subscribe(data => {
      userNotification.userId = data.id;
      this.notificationService.createNotification(userNotification).subscribe(data => {
        this.dashboardService.exportSalesFromDashboardByProductId(this.from, this.to, this.productId, this.familyId, this.marketId, this.retailerId, data.id).subscribe(data => {
        });
        setTimeout(() => {
          this.sharedDataService.changeMessage("notification:created");
        }, 60000);
      });
    })
  }
}