import { Component, OnInit, Inject} from '@angular/core';
import { PageLayoutComponent } from '@src/base-components/pagelayout/pagelayout.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { IAuthService } from '@src/auth/auth.service.interface';
import { BellRossPageType } from './../../bellross/services/bellross-page-type.service';

@Component({
  selector: 'app-page-layout-bellross',
  templateUrl: './pagelayout-bellross.component.html',
  styleUrls: ['./pagelayout-bellross.component.scss'],
  providers: [ BellRossPageType ]
})
export class PageLayoutBellRossComponent extends PageLayoutComponent implements OnInit {

  constructor(
    @Inject('AuthService') authService: IAuthService,
    modalService: NgbModal,
    route: Router,
    public bellrossPageType: BellRossPageType,
    private router: Router) {
      super(modalService, authService, route);
  }

  ngOnInit() {}

  onActivate() {}

  IsInternalPage() {
    return this.bellrossPageType.isInternal();
  }
}
