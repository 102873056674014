import { Component, Input, OnChanges} from '@angular/core';
import { Product } from '@src/model/product';
import { ConfigService } from '@src/services/layout/config.service';
import { WizardFindProductsResultComponent } from '@src/base-components/wizard/find-product/wizard-find-products-result';
import { ProductsService } from '@src/services/products/products.service';

@Component({
    selector: 'app-wizard-find-products-result-bellross',
    templateUrl: './wizard-find-products-result.html',
    styleUrls: ['../../../../base-components/wizard/find-product/wizard-find-products-result.scss']
})

export class WizardFindProductsResultComponentBellRoss extends WizardFindProductsResultComponent {//implements OnChanges{

    constructor(configService: ConfigService, public productsService: ProductsService) {
        super(configService)
    }
}