import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { IAuthService } from '@src/auth/auth.service.interface';
import { WarrantyUpdateWithComment } from '@src/model/warranty/WarrantyUpdateWithComment';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { WarrantyOperationType } from '@src/model/Enums/warrantyOperationType';
import { Router } from '@angular/router';
import { AppSettings } from '@src/core/AppSettings';
import * as moment from 'moment';
import { ModalReturnSaleComponent } from '@src/base-components/sales/sale-details/return-sale/modal-return-sale.component';
import { UserRolesEnum } from '@src/model/userRoles';


declare var require: any;
const cloneDeep  = require('lodash/cloneDeep');

@Component(
    {
        selector: 'app-modal-return-sale',
        templateUrl: './modal-return-sale.component.html'
    })

export class ModalReturnSaleBellRossComponent extends ModalReturnSaleComponent { //implements OnInit {

    model: NgbDateStruct;
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
    returnDate = new Date();
    numberofDaysallowedtoreturn: number;

    constructor(@Inject('AuthService')
        authService: IAuthService,
        warrantiesService: WarrantiesService,
        activeModal: NgbActiveModal,
        router: Router,
        appSettings : AppSettings,
        calendar: NgbCalendar
    ) {
        super(authService, warrantiesService, activeModal, router, appSettings, calendar);

    }
    // ngOnInit(): void {      
    //     const loggedUser = this.authService.getCurrentUserInstant();
    //     if (loggedUser.isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN, UserRolesEnum.AGENT])) {
    //         this.numberofDaysallowedtoreturn = this.appSettings.MaximumDaysAllowedForWarrantyReturnExtended;
    //     }
    //     else {
    //         this.numberofDaysallowedtoreturn = this.appSettings.MaximumDaysAllowedForWarrantyReturn;
    //     }
    //     const targetMaxDate = new Date();
    //     var targetMinDate = moment.utc().add((this.numberofDaysallowedtoreturn - 1) * -1, 'day').toDate();
    //     var warrantyActivationDate = this.warranties[0].warrantyActivationDate;

    //     warrantyActivationDate = new Date(warrantyActivationDate);
    //     var todayDate = new Date();      
    //     warrantyActivationDate.setHours(0,0,0,0);
    //     todayDate.setHours(0,0,0,0);
    //     // To calculate the time difference of two dates 
    //     var difference_In_Time = todayDate.getTime() - warrantyActivationDate.getTime();

    //     // To calculate the no. of days between two dates 
    //     var difference_In_Days = (difference_In_Time)  / (1000 * 3600 * 24);
        
    //     if (difference_In_Days === 0) {          
    //         targetMinDate = moment.utc().toDate();
    //     } else if (difference_In_Days <= this.numberofDaysallowedtoreturn ) {
    //         targetMinDate = moment.utc().add(difference_In_Days * -1, 'day').toDate();
    //     } else {
    //         // Allow activations to occur in the past for 1 month.
    //         targetMinDate = moment.utc().add(this.numberofDaysallowedtoreturn * -1, 'day').toDate();
    //     }
        
    //     this.minDate = { day: targetMinDate.getUTCDate(), month: (targetMinDate.getUTCMonth() + 1), year: targetMinDate.getUTCFullYear() };
    //     // Native date months start @ 0 index. NgbDateStruct starts @ 1.
    //     this.maxDate = { day: targetMaxDate.getUTCDate(), month: (targetMaxDate.getUTCMonth() + 1), year: targetMaxDate.getUTCFullYear() };
    //     this.model = this.calendar.getToday();
        
    // }

    // confirmCancelSale(event) {
        
    //     if (event.detail > 1) {
    //         return;
    //     }

    //     const saleWarranty = cloneDeep(this.warranties.filter((w) => w.type === 'sale')[0]);
    //     saleWarranty.isReturned = true;
    //     const warrantyReturn: WarrantyUpdateWithComment = {
    //         warranty: saleWarranty,
    //         comment: this.comment,
    //         operationDate: this.returnDate
    //     };
    //     this.warrantiesService.updateWarrantyWithComment(warrantyReturn)
    //         .subscribe((w) => {
    //             if (w != null) {
    //                 w.isExpired = new Date() > new Date(w.warrantyEndDate);
    //                 w.type = 'sale';
    //                 w.seller = this.sale.seller;
    //                 w.retailer = this.sale.retailer;    
    //                 this.warranties = [w];
    //                 this.operations = w.warrantyOperations;
    //                 this.sale.isReturned = w.isReturned;
    //                 this.closeCancelSaleConfirmaton();
    //                 this.returnSaleEnabled = this.editEnabled = this.correctWarrantyEnabled = this.editEnabled = false;
    //                 this.refreshWarranties.emit(this.warranties);
    //                 this.refreshReturnSaleButton.emit(this.returnSaleEnabled);
    //             } else {
    //                 // Something went wrong, user got notified already.
    //                 this.closeCancelSaleConfirmaton();
    //             }
    //         });
    // }

    // onDateSelection(date: NgbDateStruct) {
    //   var momentDate = moment.utc(date.year + '-' + date.month + '-' + date.day);

    //   if(this.warranties[0].warrantyOperations && this.warranties[0].warrantyOperations.some(m => m.operationType != WarrantyOperationType.return)) {
    //     let warrantyOperations = this.warranties[0].warrantyOperations.filter(m => m.operationType != WarrantyOperationType.return).sort((a, b) => (a.id < b.id) ? 1 : -1);

    //     var operationDate = moment.utc(warrantyOperations[0].operationDate);
    //     if (momentDate.year() == operationDate.year() && momentDate.month() == operationDate.month() && momentDate.day() == operationDate.day()) {
    //       momentDate.add(operationDate.hours(), 'hours');
    //       momentDate.add(operationDate.minutes() + 1, 'minutes');
    //     }
    //   }
      
    //   this.returnDate = momentDate.toDate();
    // }
}
