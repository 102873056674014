import { WarrantyOperation } from './warrantyOperation';
import { Retailer } from '../retailer';
import { User } from '../user';
import { WarrantyCard } from './warrantycard';
import { RepairWarranty } from '../repairWarranty';
import { WarrantyExtension } from './warrantyExtension';
import { Customer } from '../customer';

export class Warranty {
    constructor() {
        this.retailer = new Retailer();
        this.possibleSellers = [];
        this.customerInfo = null
    }
    id: number;
    productItemId: number;
    productId: number;
    warrantyCardId: number;
    warrantyCardCode: string;
    warrantyDurationMonths: number;
    type: string;
    retailerId: number;
    sellerId: string;
    warrantyActivationDate: Date;
    warrantyEndDate: Date;
    warrantyCard: WarrantyCard;
    isFake: boolean;
    isExpired: boolean;
    isReturned: boolean;
    productItemSerialNumber: string;
    productSku: string;
    productName: string;
    warrantyOperations: WarrantyOperation[];
    retailer: Retailer;
    seller: User;
    possibleSellers: User[];
    isSerializedItem : boolean;
    isLegacy: boolean;
    isCpo: boolean;
    hasBlockchainCertificate: boolean;
    repairs?: RepairWarranty[];
    serialNumber: string;
    distribution?: string;
    warrantyExtensions?: WarrantyExtension[];
    customerInfo: Customer;
    extendedWarrantyEndDate?: Date;
    soldBy: string;
    channelOption: string;    
    isOverrideWarranty: boolean;
    limitedEditionUnitNumber: number;
}
