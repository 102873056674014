import { Component} from '@angular/core';
import { PrivacyBaseComponent } from '@src/base-components/privacy/privacy-base.component';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-page-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent extends PrivacyBaseComponent{
    constructor(configService: ConfigService) {
        const params = {
            appName: 'Warranty Activation System',
            companyName: 'Bell and Ross',
            products: 'watches',
            ContactEmail : 'warranty@bellross.com'
        };
        const logoUrl = '';
        super(params, logoUrl, configService);
    }
}