import { CustomersComponent } from '../../../base-components/customers/customers.component';
import { CustomerDetailsComponent } from '../../../base-components/customers/customers-details/customer-details.component';
import { CustomerDetailsBellRossComponent } from './customers-details/customer-details.component';
import { CustomerInfoDetailsComponent } from '../../../base-components/customers/customers-details/info/customer-info-details.component';
import { CustomerInfoDetailsBellRossComponent } from './customers-details/info/customer-info-details.component';
import { CustomerProductInfoDetailsComponent } from '../../../base-components/customers/customers-details/product/customer-product-info-details.component';

export const CustomersBellRossComponents = [
    CustomersComponent,
    CustomerDetailsComponent,
    CustomerDetailsBellRossComponent,
    CustomerInfoDetailsComponent,
    CustomerInfoDetailsBellRossComponent,
    CustomerProductInfoDetailsComponent
];